body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',    monospace;
}

.MuiPaper-root {
  background-color: inherit !important;
}
.ais-Hits-item  {
  background-color: inherit !important;
  border: none !important;
  padding-left: inherit !important;
  padding-right: inherit !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  box-shadow: none !important;
}
.suggestion-list-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.gradient-border-mask {
  background-color: white !important;
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  backdrop-filter: blur(10px);
}
.gradient-border-mask::before {
  content: "";
  pointer-events: none;
  position: absolute;
  inset: 0;
  border-radius: 10px;
  border: 2px solid transparent;
  background: linear-gradient(-100deg, red, orange, green, indigo, blue) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
